import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-grid-system';
import { connect } from 'react-redux';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { productsData, setProductForGetAQuoteLink, resetProductDetails } from '../../../store/product/actions';
import ProductTitle from '../ProductTitle/index';
import USPAccessories from '../USPAccessories/index';
import ProductCarouselThumbnails from '../ProductCarouselThumbnails/index';
import HeroActionButton from '../HeroActionButton';
import { getFavoriteContactId } from '../../../store/favoriteContact/actions';
import { resetStickyMenu } from '../../../store/stickyMenu/actions';
import { ACCESSORIES_ENDPOINT } from '../productendpoint';
import { getLanguage } from '../../Common/CookieSettings/SetLanguageData';
import { setLocalStorage } from '../../Common/CookieSettings/CookieUtils';
import MetaDataForShare from '../../MetaDataForShare';
import Cookies from 'js-cookie';
import { SITE_LEISTER_TECHNOLOGIES, renderShop } from '../../../AppGlobals';
import {
  getShopProductsByVariantGids,
  getAllShopifyGlobalIdsFromProductItems,
  getShopProductsConditionNew,
  filterProductsBySkuAndReduceProductVariants
} from '../../Shopify/lib/shopify/leisterHelpers';
import { SHOPIFY_TEST_ACCESS_KEY_COOKIE_NAME, COUNTRY_CODE } from '../../Common/CookieSettings/CookieConstants';
import AddToCartTeaser from '../../Shopify/AddToCartTeaser';
import AutoHeight from '../../Common/AutoHeight/AutoHeight';
import Markdown from 'react-markdown';
import { getLegalText } from '../../../utils/legalText';

class AccessoriesHero extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      language: getLanguage(this.props.sitecoreContext),
      countryCode: '',
      shopifyTestAccessKey: '',
      shouldRenderShop: false,
      shopProduct: null,
      shopProductLoading: true,
      productItem: null,
      currentArticleNumber: null
    };
    this.props.resetStickyMenu();
  }

  componentDidMount() {
    const currentPageLink = window.location.href,
      currentPagePathname = window.location.pathname,
      { getFavoriteContactId, favoriteContactId, sitecoreContext } = this.props;

    getFavoriteContactId(favoriteContactId, sitecoreContext);
    this.setProductPathNameToLocalStorage(currentPagePathname);
    this.updateCurrentProductData(currentPageLink);
    this.add3DScriptsToBody();

    this.setState({
      countryCode: Cookies.get(COUNTRY_CODE)?.toLowerCase(),
      shopifyTestAccessKey: Cookies.get(SHOPIFY_TEST_ACCESS_KEY_COOKIE_NAME),
      currentArticleNumber: this.getCurrentArticleNumber()
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    const { productPageLinkForGetAQuote, sitecoreContext } = this.props,
      currentLanguage = getLanguage(sitecoreContext),
      currentPageLink = window.location.href,
      currentPagePathname = window.location.pathname;

    const { site } = sitecoreContext;
    const siteName = site?.name || SITE_LEISTER_TECHNOLOGIES;

    if (productPageLinkForGetAQuote !== currentPageLink) {
      // Url change should re-initiate the states for loading productItem and shopProduct
      this.setState({ language: currentLanguage, productItem: null, shopProduct: null, shopProductLoading: true });
      this.updateCurrentProductData(currentPageLink);
      this.setProductPathNameToLocalStorage(currentPagePathname);
    }
    this.setProductNameToLocalStorage();

    if (
      prevState.countryCode !== this.state.countryCode ||
      prevState.shopifyTestAccessKey !== this.state.shopifyTestAccessKey
    ) {
      const shouldRenderShop = await renderShop(this.state.countryCode, this.state.shopifyTestAccessKey, siteName);

      this.setState({ shouldRenderShop });
    }

    // Load productItem if possible, update state once loaded
    if (!this.state.productItem) {
      const productItem = this.getAccessory();
      if (productItem) {
        this.setState({ productItem });
      }
    }
    const sku = this.getCurrentArticleNumber().replace('-', '.');

    // Only proceed if we have an empty shopProduct
    if (this.state.shopProduct === null) {
      if (!this.state.shouldRenderShop) {
        this.setState({ shopProductLoading: false });
        return;
      } else if (!this.state.shopProductLoading) {
        this.setState({ shopProductLoading: true });
      }

      // Don't proceed until we have productItem and sku ready
      if (!this.state.productItem || !sku) return;

      // Check for shopifyProduct
      const shopifyGlobalIds = getAllShopifyGlobalIdsFromProductItems([this.state.productItem]);
      if (shopifyGlobalIds.length) {
        const shopProducts = await getShopProductsByVariantGids(shopifyGlobalIds);
        const shopProductsConditionNew = getShopProductsConditionNew(shopProducts);
        const shopProduct = filterProductsBySkuAndReduceProductVariants(shopProductsConditionNew, sku)[0] ?? undefined;

        if (shopProduct && this.state?.shopProduct?.id !== shopProduct.id) {
          this.setState({ shopProduct, shopProductLoading: false });
        }
      } else {
        // No shopProduct therefore stop the loading and set shopProduct to false
        this.setState({ shopProduct: false, shopProductLoading: false });
      }
    }
  }

  async add3DScriptsToBody() {
    await import('@google/model-viewer');
  }

  updateCurrentProductData(currentPageLink) {
    this.props.resetProductDetails();
    this.setProductsOnRedux();
    this.props.setProductForGetAQuoteLink(currentPageLink);
  }

  getCurrentArticleNumber() {
    const { match } = this.props,
      relativePath = window.location.pathname,
      matchArticleNumber = match && match.params && match.params.article_number ? match.params.article_number : null,
      historyStateArticleNumber =
        window.history.state && window.history.state.articleNr ? window.history.state.articleNr : null;
    if (matchArticleNumber && relativePath.indexOf(matchArticleNumber) > 0) {
      return matchArticleNumber;
    } else if (historyStateArticleNumber && relativePath.indexOf(historyStateArticleNumber) > 0) {
      return historyStateArticleNumber;
    }
    return '';
  }

  getAccessoriesId = () => {
    const { productItems } = this.props,
      currentArticleNumber = this.getCurrentArticleNumber();

    if (productItems && productItems.length && currentArticleNumber) {
      const accessoriesItem = productItems.find(
        item => item.articleNumber === currentArticleNumber.replace('-', '.').trim()
      );

      return parseInt((accessoriesItem && accessoriesItem.id) || 0);
    }
    return null;
  };

  getAccessory = () => {
    const { productItems } = this.props;
    const currentArticleNumber = this.getCurrentArticleNumber();

    if (productItems && productItems.length && currentArticleNumber) {
      const accessoriesItem = productItems.find(
        item => item.articleNumber === currentArticleNumber.replace('-', '.').trim()
      );

      return accessoriesItem;
    }

    return null;
  };

  setProductsOnRedux() {
    const { match } = this.props;
    if (match && match.params && match.params.product_name) {
      const articleNumber = match.params.article_number
          ? match.params.article_number.toString().replace('-', '.')
          : null,
        historyStateArticleNumber =
          window.history.state && window.history.state.articleNr
            ? window.history.state.articleNr.toString().replace('-', '.')
            : null,
        countryCode = Cookies.get(COUNTRY_CODE)?.toLowerCase();
      this.props.setProductsJson(
        match.params.product_name,
        historyStateArticleNumber || articleNumber,
        countryCode,
        getLanguage(this.props.sitecoreContext),
        this.props.sitecoreContext.site.name
      );
    }
  }

  setProductNameToLocalStorage() {
    const productName = this.getTitle();
    if (productName) {
      setLocalStorage('currentPageTitle', productName);
    }
  }

  setProductPathNameToLocalStorage(pathname) {
    this.triggerPageViewEvent();
    setLocalStorage('currentPageLocation', pathname, 'necessary');
  }

  getTitle = () => {
    return this.state.productItem?.articleName || this.props.accessoriesData?.MasterProductName;
  };

  triggerPageViewEvent = () => {
    const { title } = this.props;
    const pageUrl = document.location.origin + document.location.pathname + document.location.search,
      currentArticleNumber = this.getCurrentArticleNumber(),
      pageTitle = currentArticleNumber ? title + ' ' + currentArticleNumber : title;
    if (pageUrl && pageTitle) {
      window.dataLayer.push({
        event: 'pageview',
        page: {
          url: pageUrl,
          title: pageTitle // NOTE: It would be too complex to handle the async state of articleName... lets keep it like this for now
        }
      });
    }
  };

  /**
   * Renders legal text based on the country code and the presence of a shop product.
   * @returns {string|boolean} The legal text to be rendered, or false if no rendering is needed.
   */
  renderLegalText = () => {
    const renderLegalText = getLegalText(Cookies.get(COUNTRY_CODE)) ?? '';
    const hasShopProduct = !!this.state.shopProduct;
    return hasShopProduct && renderLegalText;
  };

  render() {
    if (!this.props.accessoriesData || !this.props.rendering.fields) {
      // The following placeholder, will simply improve the visual appearance of the page.
      // This because it will not show the images/content on the screen which are already loaded below.
      // This drastically improves the visual appearance of the page and the loading product details.
      return <div className='AccessoriesHero' style={{ height: '100vh' }} />;
    }
    const {
        'Get A Quote Page Link': getAQuotePageLink,
        'Find a Dealer': dealerLink,
        'Talk to an Expert': expertLink
      } = this.props.fields,
      { MasterProductMetaDescription, media } = this.props.accessoriesData,
      accessoryId = this.getAccessoriesId(),
      currentArticleNumber = this.getCurrentArticleNumber(),
      getAQuoteLink = getAQuotePageLink && getAQuotePageLink.value && getAQuotePageLink.value.href,
      imageType = 'image/jpeg',
      metaDataImage = media && media.length > 0 ? media.find(item => item.type === imageType && item.url) : [],
      {
        rendering: { fields },
        favouriteContactId,
        categoryEn,
        brand,
        subCategoryEn,
        titleEn,
        metaProductName
      } = this.props,
      productItem = this.getAccessory(),
      shopProductVariant =
        this.state.shopProduct?.variants?.find(v => v.sku === productItem?.articleNumber) ?? undefined,
      title = this.getTitle();
    const mailSubject = currentArticleNumber ? title + ' ' + currentArticleNumber : title;
    return (
      <div className='ProductDetailsHero AccessoriesHero'>
        <MetaDataForShare
          showPageData
          currentArticleNumber={metaProductName ? undefined : currentArticleNumber}
          title={metaProductName || title}
          metaDescription={MasterProductMetaDescription}
          image={metaDataImage.url}
        />
        <Row nogutter className='ProductDetailsHero-Row componentContainerMobile'>
          <Col xl={6} lg={6} className='ProductDetailsHero-Row-Left'>
            <div className='ProductDetailsHero-Row-Left-Gallery'>
              {!this.props.isLoading && <ProductCarouselThumbnails />}
            </div>
          </Col>
          <Col xl={6} lg={6} className='ProductDetailsHero-Row-Right'>
            <ProductTitle title={title} />
            <USPAccessories />
            <AutoHeight>
              {!this.props.isLoading && !this.state.shopProductLoading ? (
                <div className='ProductDetailsHero-RenderShop'>
                  <AddToCartTeaser
                    shopProduct={this.state.shopProduct}
                    productData={{ brand, category: categoryEn, subcategory: subCategoryEn, name: titleEn }}
                    productItem={productItem}
                    shopProductVariant={shopProductVariant}
                    noImage
                    showTechSpec={false}
                    findADealerProps={{
                      hasFavouriteContact: !!favouriteContactId,
                      getAQuoteLink,
                      dealerLink,
                      expertLink,
                      // Forced on accessories page
                      forceGetAQuote: true,
                      accesoriesArticleNumber: currentArticleNumber
                    }}
                    shouldRenderShop={this.state.shouldRenderShop}
                  />
                  {this.renderLegalText() && (
                    <Markdown className='LegalText Markdown--support-links'>{this.renderLegalText()}</Markdown>
                  )}
                </div>
              ) : null}
            </AutoHeight>

            <HeroActionButton
              hasBuyOption={
                this.state.shouldRenderShop &&
                this.state.shopProduct?.variants &&
                this.state.shopProduct?.variants[0]?.availableForSale
              }
              shouldDisplay
              fields={fields}
              subjectEmail={mailSubject}
              accessoriesId={accessoryId}
              getAQuotePageLink={getAQuoteLink}
              categoryEn={categoryEn}
              favouriteContact={favouriteContactId}
              accesoriesArticleNumber={currentArticleNumber}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { masterProductData, isLoading, shortMasterProductData } = state.productDetails;
  return {
    isLoading: isLoading ?? false,
    accessoriesData: masterProductData,
    productItems: state.productDetails ? state.productDetails.variants : null,
    productPageLinkForGetAQuote: state.productDetails.productPageLinkForGetAQuote,
    categoryEn: masterProductData ? masterProductData.CategoryEn : null,
    brand: masterProductData ? masterProductData.BrandName : null,
    subCategoryEn: masterProductData ? masterProductData.SubCategoryEn : null,
    titleEn: masterProductData ? masterProductData.MasterProductENName : null,
    favouriteContactId: state.favoriteContact && state.favoriteContact.favoriteContactId,
    metaProductName: shortMasterProductData?.name ? shortMasterProductData.name : null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setProductsJson: (accessories_name, article_number, countryCode, language, tenant) =>
      dispatch(
        productsData(
          ACCESSORIES_ENDPOINT(accessories_name, article_number, language, tenant),
          null,
          countryCode,
          language,
          tenant
        )
      ),
    resetProductDetails: () => dispatch(resetProductDetails()),
    resetStickyMenu: () => dispatch(resetStickyMenu()),
    setProductForGetAQuoteLink: link => dispatch(setProductForGetAQuoteLink(link)),
    getFavoriteContactId: (id, sitecoreContextFields) => dispatch(getFavoriteContactId(id, sitecoreContextFields))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(withRouter(AccessoriesHero)));
