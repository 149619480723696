import { getBundleVariantsById } from '../../Shopify/lib/shopify/bundleHelpers';
import { ShopifyBundleDataNode } from '../../Shopify/lib/shopify/types';
import { ProductItem } from '../types';

async function getBundleInfoFromProductItems(productItems: ProductItem[]): Promise<ShopifyBundleDataNode[]> {
  // Get bundle information for each product item
  const allVariantIds = productItems
    .map(productItem => productItem.shopifyGlobalIds)
    .flat()
    .filter(e => typeof e === 'string' && e !== '');
  const bundleDataNodes = await getBundleVariantsById(allVariantIds);

  // Filter condition on main product to only get bundles for new products
  return bundleDataNodes.filter(
    node => node?.product?.condition?.value !== 'used' && node?.product?.condition?.value !== 'refurbished'
  );
}

function hasProductItemBundle(bundleNodes: ShopifyBundleDataNode[], productItem: ProductItem): boolean {
  const productVariantBudleInfoBySku = bundleNodes.filter(node => node?.sku === productItem?.articleNumber);
  // We enforce taking the first one as well
  const currentProductVariantBundleData = productVariantBudleInfoBySku?.[0] || null;
  const bundles = currentProductVariantBundleData?.bundleMetafield?.bundles?.nodes ?? [];
  // If items within a bundle are not availableForSale, we don't show the whole bundle
  const bundlesAvailableForSale = bundles.filter(bundle =>
    bundle.productVariants?.references?.nodes?.every(productVariant => productVariant.availableForSale)
  );

  return bundlesAvailableForSale.length > 0;
}

export { getBundleInfoFromProductItems, hasProductItemBundle };
