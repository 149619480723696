import { Raw } from '@coveo/headless';
import { COUNTRY_CODE, LANG } from '../components/Common/CookieSettings/CookieConstants';
import { getCookie } from '../components/Common/CookieSettings/CookieUtils';

interface DownloadAdditionalData {
  gd_is_restricted?: boolean;
  source?: string;
  gd_media_language_codes?: string[];
  [key: string]: any; // For dynamic keys like `gd_media_name_${currentLanguage}`
}

interface FileData extends Raw {
  gd_cdn_url?: string;
  gd_media_assetname?: string;
  gd_is_restricted?: string | boolean;
  gd_media_language_codes?: string[];
  [key: string]: any; // For dynamic properties like media names and types
}

/**
 * Prepares download tracking data from a file object
 * @param file The file object containing raw data
 * @param language Current locale/language code
 * @returns Object with prepared download tracking parameters
 */
export const prepareDownloadTrackingUsingCoveoData = (
  file?: FileData,
  language = ''
): {
  mediaName: string;
  documentType: string;
  languages: string[];
  extension: string;
  url: string;
  assetName: string;
  isRestricted: boolean;
  additionalData: DownloadAdditionalData;
} => {
  if (!file) {
    return {
      mediaName: '',
      documentType: '',
      languages: [],
      extension: '',
      url: '',
      assetName: '',
      isRestricted: false,
      additionalData: {}
    };
  }

  const mediaName = (file[`gd_media_name_${language}`] as string) ?? '';
  const documentType = (file[`gd_media_type_${language}`] as string) ?? '';
  const languages = (file.gd_media_language_codes as string[]) || [];
  const extension = file.gd_file_extension ?? '';
  const url = (file.gd_cdn_url as string) || '';
  const assetName = (file.gd_media_assetname as string) || '';

  // Convert 'True'/'False' string to actual boolean if needed
  const isRestricted = file.gd_is_restricted === 'True' || file.gd_is_restricted === true;

  const additionalData = {
    gd_is_restricted: isRestricted,
    gd_media_language_codes: languages,
    source: file.source ?? undefined
  };

  return { mediaName, documentType, languages, extension, url, assetName, isRestricted, additionalData };
};

// Helper function to track downloads
export const trackDownload = (
  downloadUrl?: string,
  fileName?: string,
  linkText?: string,
  additionalData: DownloadAdditionalData = {}
): void => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    // Extract file extension from URL
    const fileExtension = downloadUrl ? new URL(downloadUrl).pathname.split('.').pop()?.toLowerCase() || '' : '';

    // Get current language and country from cookies
    const currentLanguage = getCookie(LANG) || '';
    const currentCountryCode = getCookie(COUNTRY_CODE)?.toLowerCase();

    window.dataLayer.push({
      event: 'file_download',
      file_extension: fileExtension,
      file_name: fileName,
      link_text: linkText || '',
      link_url: downloadUrl || '',

      // Additional requested data points
      [`gd_media_name_${currentLanguage}`]: additionalData[`gd_media_name_${currentLanguage}`] || undefined,
      [`gd_media_type_${currentLanguage}`]: additionalData[`gd_media_type_${currentLanguage}`] || undefined,
      gd_is_restricted: additionalData.gd_is_restricted || false,
      gd_media_language_codes: additionalData.gd_media_language_codes || undefined,
      source: additionalData?.source || undefined,
      location: window?.location?.pathname || undefined,
      page_language: currentLanguage || undefined,
      page_country: currentCountryCode || undefined
    });
  }
};
