import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Row, Col } from 'react-grid-system';
import MetaDataForShare from '../../../../MetaDataForShare';
import Text from '../../../Text';
import { EventDetails } from './parts/EventDetails';
import { EventLinkBooth } from './parts/EventLinkBooth';
import { EventLinkOrganizer } from './parts/EventLinkOrganizer';
import { EventInformationProps } from './types';
import { EventAddress } from './parts/EventAddress';
import EventMap from '../EventMap';

export const EventInformation = ({ fields, rendering, sitecoreContext }: EventInformationProps) => {
  if (!fields || !rendering) {
    return (
      <div>
        Unable to render Event Information component due to missing data. Note that Event resolution is not supported in
        Experience Editor.
      </div>
    );
  }

  const {
    'Event Address': eventAddress,
    'Event Coordinates Latitude': eventCoordinatesLatitude,
    'Event Coordinates Longitude': eventCoordinatesLongitude,
    'Event Booth Information': eventBoothInformation,
    'Event Link Directions': eventLinkDirections,
    'Event Venue': eventVenue,
    'Event Country': eventCountry,
    'Event City': eventCity,
    'Event Industries': eventIndustries,
    'Event End Date': eventEndDate,
    'Event Start Date': eventStartDate,
    'Event Text': eventText,
    'Event Title': eventTitle,
    'Event Link Booth': eventLinkBooth,
    'Event Link Organizer': eventLinkOrganizer,
    'Page Description': pageDescription,
    'Page Title': pageTitle,
    'Meta Image': metaImage
  } = fields;
  const { language } = sitecoreContext;

  const metaDataTitleValue = pageTitle?.value;
  const metaDataDescriptionValue = pageDescription?.value;
  const metaDataImageSrc = metaImage?.value?.src;

  return (
    <>
      <MetaDataForShare
        image={metaDataImageSrc}
        metaDescription={metaDataDescriptionValue}
        showPageData
        title={metaDataTitleValue}
      />
      <Row nogutter className='EventInformationSection componentContainer'>
        <Col offset={{ lg: 2 }} sm={12} md={12} xl={7} lg={7}>
          <h1 data-testid='event-title'>
            <Text field={eventTitle} />
          </h1>
          <EventDetails
            language={language}
            eventTitle={eventTitle}
            eventCity={eventCity}
            eventCountry={eventCountry}
            eventIndustries={eventIndustries}
            eventStartDate={eventStartDate}
            eventEndDate={eventEndDate}
            eventVenue={eventVenue}
          />
          <div className='EventInformationSection-TextContainer' data-testid='event-info'>
            <Text field={eventText} />
          </div>
          <EventAddress
            eventVenue={eventVenue}
            eventAddress={eventAddress}
            eventLinkDirections={eventLinkDirections}
            eventBoothInformation={eventBoothInformation}
          />
          <EventLinkOrganizer eventLinkOrganizer={eventLinkOrganizer} />
          <EventLinkBooth eventLinkBooth={eventLinkBooth} />
        </Col>
      </Row>
      <EventMap
        eventTitle={eventTitle}
        eventVenue={eventVenue}
        eventAddress={eventAddress}
        eventCoordinatesLatitude={eventCoordinatesLatitude}
        eventCoordinatesLongitude={eventCoordinatesLongitude}
        eventCity={eventCity}
        eventCountry={eventCountry}
      />
    </>
  );
};

export default withSitecoreContext()(EventInformation);
